import Img from "./Img";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactLine from "./ContactLine";

function Services() {
    return (
        <>
            <div className="container box text-center">
                <h1>Szolgáltatásaim</h1>

                <h2>Online oktatás</h2>
                {<Img bordered="true" width="200" height="200" side="left" src={require("../images/online-learning.png")}/>}

                <p>
                    Az online tanulás elkezdéséhez semmi másra nincs szükség, csak egy Google fiókra, 
                    illetve egy mikrofonra. Hogy ne vesztegessük az időt, mire elkezdjük az órát, 
                    én már pontokba szedem az oktatási anyagot, amit szeretnék átadni neked, 
                    amire szükséged van.
                </p>
                <p>
                    Az órákat <b>Google Meet</b>-en, vagy <b>Discord</b>-on tartom.
                    Ha számodra kényelmesebb egy (nem fizetős) alternatíva,
                    akkor tudok alkalmazkodni.
                </p>

                <div className="clear"></div>
                <h2>Házi feladat segítség</h2>
                {<Img bordered="false" width="200" height="200" side="right" src={require("../images/homework.png")}/>}
                <p>
                    Olyan feladatot kaptál, amit nem tudsz megoldani? Sebaj, én elmagyarázom neked. 
                    Megoldani ugyan nem fogom helyetted, de felruházlak azzal a szellemi munícióval, 
                    ami ahhoz szükséges, hogy (amikor nem hallja a tanárod) azt mondhasd, ezzel akartál 
                    szívózni? Engem nem lehet ilyen könnyen lenyomni! Kivéve persze, ha jófej a tanárod, 
                    akkor ne mondj ilyeneket! 
                </p>

                <div className="clear"></div>
                
                <p>
                    <h2>Vizsgafelkészítés</h2>
                    {<Img bordered="true" width="200" height="200" side="left" src={require("../images/exam.png")}/>}

                    Úgy érzed, hogy nem nagyon érted a vizsgaanyagot, de azért nem szeretnél 
                    megbukni? Ne aggódj egy percig sem, segítek rajtad. Küldd el nekem előzetesen 
                    a tananyagot, én feldolgozom helyetted, majd leadom az órát belőle úgy, hogy 
                    megértsd. Ne feledd, engem azért fizetsz, hogy átadjam a tudást nem azért, 
                    hogy leadjam az órát! Az alábbiakban leírtam a felkészítés menetét.
                </p>

                <div className="clear"></div>

                <div className="text-center">
                    <h2>
                        <FontAwesomeIcon icon="fa-solid fa-money-bill-1"  className="color-secondary mr5"/> 
                        A szolgáltatások ára 6500 Ft/óra
                    </h2>
                </div>
            </div>

            {<ContactLine bgcolor="bg-dark-grey"/>}
        </>
    );
}

export default Services;